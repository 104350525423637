import type { ReadonlySignal } from '@preact/signals-core';
import {
  batch as preactBatch,
  computed as preactComputed,
  useComputed as preactUseComputed,
  effect as preactEffect,
  type Signal,
  signal as preactSignal,
} from '@preact/signals-react';
import { parse, v4 } from 'uuid';

// Tom is not using ApiError for API exceptions. This is a placeholder until
// that is updated and ApiError is correct.
export interface CustomError {
  message?: string;
  response?: {
    status?: number;
  };
}

// todo (Tom): When the ApiError is updated and used for api exceptions, the
//  CustomError interface can be removed and these checks for CustomError
//  can be replaced with ApiError.
// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const isApiError = (error: any): error is CustomError => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return !!(error?.message && error?.timestamp && error.status);
  // return instanceOfApiError(error);
};

export const toRaw = (val: unknown): unknown => {
  if (Array.isArray(val)) return val.map((val) => toRaw(val));
  if (val instanceof Object)
    return Object.fromEntries(
      Object.entries(Object.assign({}, val)).map(([k, v]) => [k, toRaw(v)]),
    );
  return val;
};

export const toRaw2 = (val: unknown): unknown => {
  if (Array.isArray(val)) return val.map((val) => toRaw2(val));
  if (val instanceof Object)
    return Object.fromEntries(
      Object.entries(Object.assign({}, val))
        .filter(([k, _]) => k !== 'parent')
        .map(([k, v]) => [k, toRaw2(v)]),
    );
  return val;
};

/**
 * Creates a debounced function that delays invoking `func` until after `delay`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked.
 *
 * @param func - The function to debounce.
 * @param delay - The number of milliseconds to delay.
 * @returns A new debounced function.
 */
export const debounce = <Args extends unknown[]>(
  func: (...args: Args) => void,
  delay: number,
) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  // noinspection UnnecessaryLocalVariableJS
  const debounceHandler = (...args: Args) => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };

  return debounceHandler;
};

export const debounceSignal = <T>(
  targetSignal: Signal<T>,
  timeoutMs = 0,
): Signal<T> => {
  const debounceSignal = preactSignal<T>(targetSignal.value);

  preactEffect(() => {
    const value = targetSignal.value;
    const timeout = setTimeout(() => {
      debounceSignal.value = value;
    }, timeoutMs);
    return () => {
      clearTimeout(timeout);
    };
  });

  return debounceSignal;
};

const v4Base64UUID = () => {
  const bytes = parse(v4());
  const base64 = btoa(String.fromCharCode(...bytes));
  return base64.substring(0, 22);
};

const invalidChars = /[.,/~+*?%]/gi;

export const base64UUID = () => {
  let uuid = '';
  while (!uuid || uuid.match(invalidChars)) {
    uuid = v4Base64UUID();
  }
  return uuid;
};

export const cx = (...args: unknown[]) => {
  return args
    .filter((arg) => {
      if (typeof arg === 'string') {
        return !!arg.trim();
      }
      if (typeof arg === 'object') {
        const o = arg as Record<string, unknown>;
        return Object.keys(o).some((key) => o[key]);
      }
      return false;
    })
    .join(' ');
};

export const clone = <T>(obj: T): T => {
  const s = 'Cloning';
  if (isDevMode()) {
    console.time(s);
  }
  try {
    return structuredClone(obj);
  } finally {
    if (isDevMode()) {
      console.timeEnd(s);
    }
  }
};

export const cleanAndClone = <T>(obj: T): T => {
  const s = 'Cleaning (removing undefined) and cloning';
  if (isDevMode()) {
    console.time(s);
  }
  try {
    if (obj === null || obj === undefined) return obj;
    return JSON.parse(JSON.stringify(obj)) as T;
  } finally {
    if (isDevMode()) {
      console.timeEnd(s);
    }
  }
};

export function isArray(x: unknown) {
  return Object.prototype.toString.call(x) === '[object Array]';
}

export class AssertionError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message ?? 'Unspecified error', options);
  }
}

export const isPlainObject = (prop: unknown) =>
  Object.prototype.toString.call(prop) === '[object Object]';

export const assertExists = (o: unknown, message: string) => {
  assert(o !== undefined && o !== null, message);
};

export const assertType = <T>(o: T | null | undefined, message: string): T => {
  assert(o !== undefined && o !== null, message);
  return o as T;
};

// noinspection JSUnusedGlobalSymbols
export const assertEqual = (
  actual: unknown,
  expected: unknown,
  message: string,
) => {
  return assert(actual === expected, message);
};

export const assert = (cond: boolean, message: string) => {
  if (!cond) throw new AssertionError(message);
};

const isDevMode = () => {
  return import.meta.env.MODE === 'development';
  // return true;
};

export const timeLog = <T>(func: () => T, message?: string): T => {
  if (!isDevMode()) {
    return func();
  }

  const s = message ?? `Timing ${func.toString()}`;

  console.time(s);
  try {
    return func();
  } finally {
    console.timeEnd(s);
  }
};

export const debugLog = (...args: unknown[]) => {
  if (isDevMode()) {
    try {
      const s: unknown = args.shift() ?? 'Debug Log';
      if (args.length > 0) console.log(s, args);
      else console.log(s);
    } catch (e) {
      console.warn('Failed to log', e);
    }
  }
};

export const signal = <T>(initial: T, label?: string): Signal<T> => {
  const result = preactSignal(initial);
  if (isDevMode()) {
    preactEffect(() => {
      debugLog(
        `${label ?? 'Unlabeled Signal'} has been modified`,
        result.value,
      );
    });
  }
  return result;
};

export const computed = <T>(
  compute: () => T,
  label?: string,
): ReadonlySignal<T> => {
  const result = preactComputed(compute);
  if (isDevMode()) {
    preactEffect(() => {
      debugLog(
        `${label ?? 'Unlabeled Computed'} has been modified`,
        result.value,
      );
    });
  }
  return result;
};

export const useSignal = <T>(initial: T, label?: string): Signal<T> => {
  const result = useSignal(initial);
  if (isDevMode()) {
    preactEffect(() => {
      debugLog(
        `${label ?? 'Unlabeled Signal'} has been modified`,
        result.value,
      );
    });
  }
  return result;
};

export const useComputed = <T>(
  compute: () => T,
  label?: string,
): ReadonlySignal<T> => {
  const result = preactUseComputed(compute);
  if (isDevMode()) {
    preactEffect(() => {
      debugLog(
        `${label ?? 'Unlabeled Signal'} has been computed`,
        result.value,
      );
    });
  }
  return result;
};

export const batch = <T>(callback: () => T, label = v4Base64UUID()): T => {
  return preactBatch(() => {
    const start = performance.now();
    try {
      debugLog(`Begin Batch - ${label}`);
      return callback();
    } finally {
      debugLog(
        `End Batch - ${label} (${(performance.now() - start).toFixed(1)} ms)`,
      );
    }
  });
};

export const effect = (
  compute: () => unknown,
  label = v4Base64UUID(),
): VoidFunction => {
  return preactEffect(() => {
    const start = performance.now();
    try {
      debugLog(`Begin Effect - ${label}`);
      compute();
    } finally {
      debugLog(
        `End Effect - ${label} (${(performance.now() - start).toFixed(1)} ms)`,
      );
    }
  });
};

export const subsetArray = <T>(arr: T[], partitions: number): T[][] => {
  // shallow copy of original array
  const a = [...arr];

  const partitionSize = Math.ceil(a.length / partitions);
  const result: T[][] = [];

  while (a.length > 0) {
    result.push(a.splice(0, partitionSize));
  }

  return result;
};

export const convertWildcard = (pattern = '*'): RegExp | null => {
  const regex = pattern
    .replaceAll('(', '\\(')
    .replaceAll(')', '\\)')
    .replaceAll('{', '\\{')
    .replaceAll('}', '\\}')
    .replaceAll('/', '\\/}')
    .replaceAll('+', '\\+')
    .replaceAll('.', '\\.')
    .replaceAll('*', '.*')
    .replaceAll('?', '.');

  try {
    return new RegExp(regex, 'ig');
  } catch (_e) {
    return null;
  }
};
